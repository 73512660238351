import React, { useState, forwardRef } from 'react';
import Layout from '../Layout/Layout';
import {Row, Col} from 'react-bootstrap';
import { 
	List,
	FlexboxGrid,
	Button,
	Modal,
	Placeholder,
	Message,
	Form,
	Input,
	Radio, 
	RadioGroup,
	SelectPicker,
	ButtonToolbar } from 'rsuite';
import ImageIcon from '@rsuite/icons/legacy/Image';
import FilmIcon from '@rsuite/icons/legacy/Film';
import UserCircleIcon from '@rsuite/icons/legacy/UserCircleO';
import { Link, useNavigate } from "react-router-dom";

interface Product {
	id: number,
	title: string,
	description: string,
	puntos: number,
	tags: string[]
}

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px'
};

const slimText = {
  fontSize: '0.666em',
  color: '#97969B',
  fontWeight: 'lighter',
  paddingBottom: 5
};

const titleStyle = {
  paddingBottom: 5,
  whiteSpace: 'nowrap',
  fontWeight: 500
};

const dataStyle = {
  fontSize: '1.2em',
  fontWeight: 500
};

const checkStyles = {
  radioGroupLabel: {
    padding: '8px 2px 8px 10px',
    display: 'inline-block',
    verticalAlign: 'middle'
  }
};


const Checkout = () => {

	const defaultProducts: Product[] = [
		{
			id: 1,
			title: 'Llavero copa libertadores',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 30,
			tags: [
				'Accesorios',
				'Futbol'
			]
		},
		{
			id: 2,
			title: 'Polera hombre',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 100,
			tags: [
				'Polera'
			]
		},
		{
			id: 3,
			title: 'Polera mujer',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 400,
			tags: [
				'Mujer',
				'Ropa'
			]
		},
		{
			id: 4,
			title: 'Vaso colocolo',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 1000,
			tags: [
				'Accesorios'
			]
		},
		{
			id: 5,
			title: 'Gorra colocolor',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'Gorras',
				'Accesorios'
			]
		},
		{
			id: 6,
			title: 'Gorra colocolor 2',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'Gorras',
				'Accesorios'
			]
		}
	];




	const [open, setOpen] = useState(false);

	const history = useNavigate();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		history('/');
	};

	const Textarea = forwardRef((props: any, ref: any) => <Input {...props} as="textarea" ref={ref} />);

	const [tipoEntrega, setTipoEntrega] = useState('retiro');

	const onRadio = (value: any) => {
		setTipoEntrega(value);
	}

	const [options_comuna, setoptions_comuna] = useState([]);
	const [region, setRegion] = useState(0);
	const [comuna, setComuna] = useState(0);
	const [genero, setGenero] = useState('');

	const options_region = [

		{ value: 1, name: "I Región de Tarapacá" },
		{ value: 2, name: "II Región de Antofagasta" },
		{ value: 3, name: "III Región de Atacama" },
		{ value: 4, name: "IV Región de Coquimbo" },
		{ value: 5, name: "V Región de Valparaíso" },
		{ value: 6, name: "VI Región del Libertador General Bernardo O'Higgins" },
		{ value: 7, name: "VII Región del Maule" },
		{ value: 8, name: "VIII Región del Biobío" },
		{ value: 9, name: "IX Región de la Araucanía" },
		{ value: 10, name: "X Región de Los Lagos" },
		{ value: 11, name: "XI Región de Aysén" },
		{ value: 12, name: "XII Región de Magallanes y Antártica" },
		{ value: 13, name: "Región Metropolitana" },
		{ value: 14, name: "XIV Región de Los Ríos" },
		{ value: 15, name: "XV Región de Arica y Parinacota" },
		{ value: 16, name: "XVI Región de Ñuble" },
	];

	const regionSelector = forwardRef((props: any, ref: any) => (
		<SelectPicker
		  cleanable={false}
		  data={options_region}
		  valueKey="value"
		  labelKey="name"
		  {...props}
		  ref={ref}
		/>
	));

	const comunaSelector = forwardRef((props: any, ref: any) => (
		<SelectPicker
		  defaultValue={comuna}
		  cleanable={true}
		  data={options_comuna}
		  valueKey="id"
		  labelKey="nombre"
		  {...props}
		  ref={ref}
		/>
	));

	const getComumunes = async (id: number) => {
	};

	return (
		<Layout>
			<Row>
				<Col xs={12} md={6} lg={6}>
					<h3>
						{ tipoEntrega === 'retiro' ? 'Retiro en tienda' : 'Despacho'}
					</h3>
					<br />

					<RadioGroup name="radioList" inline 
								appearance="picker" 
								value={tipoEntrega} 
								defaultValue="retiro" 
								onChange={onRadio}>

						<Radio value="retiro">Retiro en tienda</Radio>
						<Radio value="despacho">Despacho</Radio>
				    </RadioGroup>

					{
						tipoEntrega === 'despacho' && (
							<>
								<Form className="border p-3 mt-3">
									<p className="text-muted">
										<strong>Datos de entrega</strong>
									</p>
									<br />
									<Row className="">
							        	<Col xs={12} sm={6} md={6} className="mb-3">
								          <Form.ControlLabel>Región*</Form.ControlLabel>
								          <Form.Control
												style={{ minWidth: '100%'}}
												defaultValue={region}
												value={region}
												name="region"
												accepter={regionSelector}
												placeholder="Seleccionar Región"
												onChange={(e) => {
												getComumunes(e);
												}}
								            />								        </Col>
								        <Col xs={12} sm={6} md={6} className="mb-3">
								          <Form.ControlLabel>Comuna*</Form.ControlLabel>
								          <Form.Control
								            	style={{ width: '100%'}}
												defaultValue={comuna}
												value={comuna}
												name="comuna"
												accepter={comunaSelector}
												onChange={ (e) => setComuna(e) }
								  				placeholder="Seleccionar Comuna"
								            />
								        </Col>
								    </Row>

									<Row className="mb-3">
										<Col xs={12} md={6} lg={6}>
											<label>Dirección</label>
											<Form.Control name="name" />
										</Col>
										<Col xs={12} md={6} lg={6}>
											<Row>
												<Col xs={6} md={6} lg={6}>
													<label>Número</label>
													<Form.Control name="name" style={{ maxWidth: '100%'}}/>
												</Col>

												<Col xs={6} md={6} lg={6}>
													<label> Dpto/Block/Casa </label>
													<Form.Control name="name" style={{ maxWidth: '100%'}}/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</>
						)
					}

					<Form layout="horizontal" className="mt-3">

						<Row className="mb-3">
							<Col xs={12} md={6} lg={6}>
								<Form.Group controlId="name-6">
									<label>Nombre</label>
									<Form.Control name="name" />
								</Form.Group>
							</Col>
							<Col xs={12} md={6} lg={6}>
								<Form.Group controlId="name-6">
									<label>Apellido</label>
									<Form.Control name="name" />
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col xs={12} md={6} lg={6}>
								<Form.Group controlId="name-6">
									<label>RUT</label>
									<Form.Control name="name"/>
								</Form.Group>
							</Col>
							<Col xs={12} md={6} lg={6}>
								<Form.Group controlId="name-6">
									<label>Email</label>
									<Form.Control name="name"/>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col xs={12} md={12} lg={12}>
								<label>Celular</label>
								<Form.Control name="name" />
							</Col>
						</Row>

						<Row className="mb-3">
							<Col xs={12} md={12} lg={12}>
								<label>Información Adicional</label>
      							<Form.Control rows={5} style={{width: '100%'}} name="textarea" accepter={Textarea} />
							</Col>
						</Row>
					</Form>

				</Col>
				<Col xs={12} lg={6} md={6}>
					<h3>Productos a canjear</h3>
					<br />
					<List hover>
				    {defaultProducts.map((item, index) => (
				        <List.Item key={item.id} index={index + 1}>
				          <FlexboxGrid>
				            {/*icon*/}
				            <FlexboxGrid.Item colspan={2} style={styleCenter}>
								<img src="https://admin.csdcolocolo.cl/imagenes/productos/CamisetaFutsalHombreArquero1.jpeg" style={{
									maxWidth: '100%'
								}}/>
				            </FlexboxGrid.Item>
				            {/*base info*/}
				            <FlexboxGrid.Item
				              colspan={6}
				              style={{
				                ...styleCenter,
				                flexDirection: 'column',
				                alignItems: 'flex-start',
				                overflow: 'hidden'
				              }}
				            >
				            <div>{item.title}</div>

				            </FlexboxGrid.Item>
				                        <FlexboxGrid.Item colspan={6} style={styleCenter}>
				              <div style={{ textAlign: 'right' }}>
				                <div style={slimText}>Puntos</div>
				                <div style={dataStyle}>{item.puntos}</div>
				              </div>
				            </FlexboxGrid.Item>

							<FlexboxGrid.Item
				              colspan={4}
				              style={{
				                ...styleCenter
				              }}
				            >

								<Button color="red" appearance="ghost">
							        Quitar
								</Button>
				            </FlexboxGrid.Item>

				          </FlexboxGrid>
				        </List.Item>
				      ))}
				    </List>
					<div className="py-3">
						<Row>
							<Col xs={5} lg={5} md={5}>
								<h6>Total puntos </h6>
							</Col>
							<Col xs={7} lg={7} md={7}>
								<p style={dataStyle}>3000 puntos</p>
							</Col>
						</Row>
						<Row>
							<Col xs={5} lg={5} md={5}>
								<h6>Total canje</h6>
							</Col>
							<Col xs={7} lg={7} md={7}>
								<p style={dataStyle}>2530 puntos</p>
							</Col>
						</Row>
						<small>*Puntos restantes: 470 puntos</small>
					</div>
					<Button color="green" appearance="ghost" style={{ width: '100%'}} onClick={handleOpen}>
				        Canjear
					</Button>


					<Modal size={'xs'} open={open} onClose={handleClose}>
						<Modal.Header>
							<Modal.Title>Canje exitoso</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Message showIcon type="success" header="Canje exitoso">
						      	Su canje ha sido procesado exitosamente.
						      	<hr />
						      	En los proximos minutos llegara una copnfirmacion mediante correo electronico.
						    </Message>
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={handleClose} appearance="primary">
								Aceptar
							</Button>
						</Modal.Footer>
			      	</Modal>
				</Col>
			</Row>
		</Layout>
	)
}

export default Checkout;