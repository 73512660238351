import React from "react";
import logo from "../../Assets/Images/logotipo.svg";

const Logo = () => {
  return (
    <>
      <img src={logo} alt="CSD COLOCOLO LOGO" className="logo" width={50} style={{background: 'white'}} />
    </>
  );
};

export default Logo;
