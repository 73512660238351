import React, { useEffect, useState } from "react";
import { Container, Badge, Nav, Navbar, NavDropdown} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "rsuite";
import Logo from "../Logo/Logo";
import LocalStorage from "../Services/LocalStorage";
import * as Api from "../../api";
import './Header.scss';

const HeaderComponent = ({ show }: any ) => {

	const history = useNavigate();

	useEffect( () => {
	}, []);

	return (
		<>
			<Navbar bg="dark" expand="lg" fixed="top">
			<Container>
				<Navbar.Brand>
					<a href="/">
						<Logo />
					</a>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto nav">
						<Link to="/" className="nav-link">
							Home
						</Link>
						<Link to="/checkout" className="nav-link">
							Checkout
						</Link>
						<Link to="#" className="nav-link">
							Puntos: <Badge bg="danger">3000</Badge>
						</Link>
					</Nav>

					<Avatar
						size="lg"
						circle
						src={`https://picsum.photos/200/300`}
						className="me-2"
						/>
					<Navbar.Text className="box-user">
						<p className="m-0">
							Bienvenido <strong>Cristian</strong>
						</p>
						<p className="m-0 close-sesion">
							<a href="#">Cerrar Sesión</a>
						</p>
					</Navbar.Text>
				</Navbar.Collapse>
			</Container>
			</Navbar>



		</>
	);
};

export default HeaderComponent;
