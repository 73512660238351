import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from './components/Home/Home';
import Checkout from './components/Checkout/Checkout';

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
	{ path: "/checkout", element: <Checkout /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
