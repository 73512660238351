import { useState } from 'react';
import Layout from '../Layout/Layout';
import { 
	Panel,
	Container,
	Pagination,
	Divider,
	Badge,
	Button,
	Modal,
	ButtonToolbar,
	Placeholder,
	Grid, 
	TagPicker } from 'rsuite';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Row, Col} from 'react-bootstrap';

interface Product {
	id: number,
	title: string,
	description: string,
	puntos: number,
	tags: string[]
}

const Home = () => {

	const tags = ['Ropa','Futbol','Polera','Mujer','Hombre','Gorras','shorts', 'vasos','Accesorios'].map(
	  item => ({ label: item, value: item })
	);

	const defaultProducts: Product[] = [
		{
			id: 1,
			title: 'Llavero copa libertadores',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 30,
			tags: [
				'Accesorios',
				'Futbol'
			]
		},
		{
			id: 2,
			title: 'Polera hombre',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 100,
			tags: [
				'Polera'
			]
		},
		{
			id: 3,
			title: 'Polera mujer',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 400,
			tags: [
				'Mujer',
				'Ropa'
			]
		},
		{
			id: 4,
			title: 'Vaso colocolo',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 1000,
			tags: [
				'Accesorios'
			]
		},
		{
			id: 5,
			title: 'Gorra colocolor',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'Gorras',
				'Accesorios'
			]
		},
		{
			id: 6,
			title: 'Gorra colocolor 2',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'Gorras',
				'Accesorios'
			]
		},
		{
			id: 7,
			title: 'Gorra colocolor 3',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'Gorras',
				'Accesorios'
			]
		},
		{
			id: 8,
			title: 'Gorra colocolor',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'Accesorios'
			]
		},
		{
			id: 9,
			title: 'Short negro XL',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'shorts',
			]
		},
		{
			id: 10,
			title: 'Short black XL',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'shorts'
			]
		},
		{
			id: 11,
			title: 'Vaso colocolo',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'vasos',
			]
		},
		{
			id: 12,
			title: 'Vaso colocolo 2',
			description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
			puntos: 500,
			tags: [
				'vasos'
			]
		}
	];
	const [products, setProducts] = useState(defaultProducts);

	const setActivePage = () => {

	}

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onPickTag = (values: string[], event: object) => {

		if (values.length === 0) {
			setProducts(defaultProducts);
			return;
		}

		const filtered = defaultProducts.filter ( (item: Product) => {

			const found: string|undefined = item.tags.find( tag => {
				return values.find( (val: string) => val === tag)
			})

			return found;
		});

		setProducts(filtered);
	}

	const [search, setSearch] = useState('');

	const onType = (event: any) => {
		setSearch(event.target.value);
	}

	const onSearch = (event: object) => {

		if (!search) {
			setProducts(defaultProducts);
			return;
		}

		const filtered = defaultProducts.filter ( (item: Product) => {
			return item.title === search;
		});

		setProducts(filtered);
	}

	return (
		<Layout>
			<h3 className="py-3">Productos canjeables</h3>
			  <Grid fluid>
			  	<Row>
					<Col md={12} lg={6} xs={12} className="mb-3">
						<label>Busqueda por nombre:</label>
						<div className="input-group">
							<input className="form-control" placeholder="Nombre producto" onChange={onType} />
							<button className="btn btn-danger" onClick={ onSearch }>Buscar</button>
						</div>
				    </Col>
					<Col md={12} lg={6} xs={12}>
				    	<label>Filtrar por tags:</label>
				    	<TagPicker data={tags} style={{ width: '100%'}} onChange={onPickTag}/>
				    </Col>
			  	</Row>
				<Row>
					{
						products.map ( item => (
						<Col xs={12} md={3} lg={3} style={{ minHeight: '485px'}} key={item.id}>
							<Panel shaded bordered bodyFill style={{ 
									display: 'inline-block',
									marginTop: '10px', 
									minHeight: '470px' 
							}}>
								<img src="https://admin.csdcolocolo.cl/imagenes/productos/CamisetaFutsalHombreArquero1.jpeg" width={'100%'} height={200} />
								<Panel>

									<div className="product-title" style={{ height: '50px'}}>
										<a href="#" onClick={handleOpen} style={{fontSize: '16px'}}>{item.title}</a>
									</div>

									<Modal open={open} onClose={handleClose} size="md" overflow={false}>
										<Modal.Header>
											<Modal.Title>{item.title}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Row>
												<Col md={6} lg={6} xs={12}>
													<img src="https://admin.csdcolocolo.cl/imagenes/productos/CamisetaFutsalHombreArquero1.jpeg" width={'300'} />
												</Col>

												<Col md={6} lg={6} xs={12}>
													<p style={{ fontSize: '16px'}}>{item.title}</p>
													<p className="text-bold">{item.description}</p>
													<div>
														<Badge content={item.puntos} maxCount={10000}/> Puntos
													</div>
													<br />
													<p>
														<Button color="blue" appearance="ghost">
															Canjear 
														</Button>
													</p>
												</Col>
											</Row>
										</Modal.Body>
									</Modal>
									<p style={{ height: '70px'}}>{ item.description }</p>
									<div style={{ height: '25px'}}>
										<Badge content={item.puntos} maxCount={10000}/> Puntos
									</div>
									<br />
									<p>
										<Button color="blue" appearance="ghost" style={{ width: '100%'}}>
											Canjear 
										</Button>
									</p>
								</Panel>
							</Panel>
						</Col>
						))
					}
				</Row>
				<Divider />
				<Row className="d-flex justify-content-center">
				    <Col md={12}>
				    	<Pagination
				    		className="d-flex justify-content-center"
					        prev
					        next
					        size="md"
					        total={100}
					        limit={10}
					        activePage={1}
					        onChangePage={setActivePage}
					      />
				    </Col>
				</Row>

			</Grid>
		</Layout>
	)
};

export default Home;