import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Content, Sidenav } from "rsuite";
import Footer from "../Footer/Footer";
import HeaderComponent from "../Header/Header";
import LocalStorage from "../Services/LocalStorage";

const Layout = (props: any) => {

	const history = useNavigate();

	useEffect ( () => {

	});

	return (
	<>
		<HeaderComponent show={true} />
		<Content className={"mt-5 pt-5"}>
			<Container className="white my-4">
				<Row>
					<Col xs={12} md={12}>
					  {props.children}
					</Col>
				</Row>
			</Container>
		</Content>
		<Footer />
	</>
	);
};

export default Layout;
