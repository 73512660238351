import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
import { Panel } from 'rsuite';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-6 col-md-6 text-center text-md-start">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="col-12 col-md-6 text-center text-md-end">
            <span className="copyright">
              Copyright 2022 ColoColo. All Rights Reserved. Desarrollado por{" "}
              <a
                href="https://gux.cl/"
                target="_blank"
                rel="noreferrer"
                className="link tx-white"
              >
                GUX
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
